import first from "lodash/first";
import last from "lodash/last";
import split from "lodash/split";
import { GenericDocument } from "types";

export const MEET_DOC_PREFIX = "m";
export const RESTRICTED_MEET_DOC_PREFIX = "n";
export const PLATFORM_DOC_PREFIX = "p";
export const LIFTER_DOC_PREFIX = "l";
export const RESTRICTED_LIFTER_DOC_PREFIX = "s";
export const ATTEMPT_DOC_PREFIX = "a";
export const DIVISION_DOC_PREFIX = "d";
export const WEIGHT_CLASS_DOC_PREFIX = "w";
export const REF_DOC_PREFIX = "r";
export const ENTRY_DOC_PREFIX = "e";
export const RECORDS_DOC_PREFIX = "c";

export const DOC_PARENT_SEPARATOR = "-";

export const generateId = function (
  prefix: string,
  parentId?: string | null | undefined
) {
  const chars = "1234567890abcdefghijklmnopqrstuvxyz";

  let string = "";
  for (let i = 0; i < 11; i++) {
    const rand = chars[Math.floor(Math.random() * chars.length)];
    string += rand;
  }

  let id = prefix + string;
  if (parentId) {
    id += DOC_PARENT_SEPARATOR + parentId;
  }

  return id;
};

export const getDocType = function (doc: GenericDocument) {
  return first(doc._id);
};

export const getParentId = function (doc: GenericDocument) {
  return last(split(doc._id, DOC_PARENT_SEPARATOR)) ?? "";
};
