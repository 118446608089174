import React, { Component } from "react";
import { connect } from "react-redux";
import Conflict from "./Conflict";
import "./ConflictsIndex.scss";

import { getCurrentMeet, getConflicts } from "selectors";
import map from "lodash/map";
import { Meet } from "types";

class ConflictsIndex extends Component<{ meet: Meet; conflicts: any }, {}> {
  render() {
    const { meet, conflicts } = this.props;

    return (
      <div className="conflicts-index">
        <div className="page-title">Conflicts</div>
        {map(conflicts, (docTypeConflicts, docType) => {
          return (
            <div key={docType}>
              <div className="doc-type-title">{docType}</div>
              {map(docTypeConflicts, (docConflicts, docId) => {
                return (
                  <div key={docId}>
                    {map(docConflicts.losers, (loser, id) => {
                      return (
                        <Conflict
                          meet={meet}
                          winner={docConflicts.winner}
                          loser={loser}
                          key={id}
                        />
                      );
                    })}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state: any, props: any) => {
  return {
    meet: getCurrentMeet(state, props),
    conflicts: getConflicts(state, props),
  };
};

const actions = {};

export default connect(mapStateToProps, actions)(ConflictsIndex);
