import React from "react";
import { parseDateString } from "util/dateHelper";
import SortableTable from "components/SortableTable";
import {
  getGenderOptions,
  getRawOrEquippedOptions,
  getPlatformOptions,
  getSessionOptions,
  getFlightOptions,
  getAwardDivisionOptions,
  getWeightClassOptions,
  getDrugTestOptions,
  getStateOptions,
  getTeamOptions,
  getCountryOptions,
} from "util/options";
import { getBenchLabel, getStateLabel } from "util/meetHelper";
import DefaultInputCell from "components/cells/DefaultInputCell";
import AgeCell from "./cells/AgeCell";
import RackHeightCell from "./cells/RackHeightCell";
import RestrictedCell from "./cells/RestrictedCell";
import AdditionalItemsCell from "./cells/AdditionalItemsCell";
import TeamCell from "./cells/TeamCell";
import AttemptCell from "./cells/AttemptCell";
import AddDivisionCell from "./cells/AddDivisionCell";
import DivisionCell from "./cells/DivisionCell";
import WeightClassCell from "./cells/WeightClassCell";
import ButtonCell from "./cells/ButtonCell";
import { Lifter, MediaSize, Meet, WeightClass } from "types";
import toLower from "lodash/toLower";
import get from "lodash/get";
import toNumber from "lodash/toNumber";
import each from "lodash/each";
import size from "lodash/size";
import { Column } from "components/table/Table";
import {
  getLifterAwardsWeightClassDoc,
  getLifterDeclaredAwardsWeightClass,
} from "util/lifterHelper";

const LiftersTable = ({
  meet,
  lifters,
  media,
}: {
  meet: Meet;
  lifters: Lifter[];
  media: MediaSize;
}) => {
  const getAllColumns = (): Column[] => {
    const columns: Column[] = [
      {
        key: "",
        label: "",
        width: 45,
        renderer: ButtonCell,
      },
      {
        key: "name",
        label: "Name",
        width: 180,
        type: "text",
        sortable: true,
        sortFunction: (row: any) => toLower(row.name),
        sortDirection: "asc",
        sortOrder: 2,
        renderer: DefaultInputCell,
      },
      {
        key: "team",
        label: "Team",
        width: 200,
        type: "select",
        options: getTeamOptions,
        sortable: true,
        sortFunction: (row: any) => toLower(row.team),
        sortDirection: "asc",
        sortOrder: 14,
        renderer: TeamCell,
      },
      {
        key: "lot",
        label: "Lot",
        width: 100,
        type: "number",
        sortable: true,
        sortDirection: "asc",
        sortOrder: 1,
        renderer: DefaultInputCell,
      },
      {
        key: "platformId",
        label: "Platform",
        width: 200,
        type: "select",
        options: getPlatformOptions,
        sortable: true,
        sortFunction: (row: any) =>
          get(meet, ["platforms", row.platformId, "name"]),
        sortDirection: "asc",
        sortOrder: 5,
        renderer: DefaultInputCell,
      },
      {
        key: "session",
        label: "Session",
        width: 100,
        type: "select",
        options: getSessionOptions,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 4,
        renderer: DefaultInputCell,
      },
      {
        key: "flight",
        label: "Flight",
        width: 100,
        type: "select",
        options: getFlightOptions,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 3,
        renderer: DefaultInputCell,
      },
      {
        key: "birthDate",
        label: "Birth Date",
        width: 120,
        type: "date",
        sortable: true,
        sortFunction: (row: any) =>
          parseDateString({ dateString: row.birthDate, meet: meet }),
        sortDirection: "asc",
        sortOrder: 6,
        renderer: DefaultInputCell,
      },
      {
        key: "age",
        label: "Age",
        width: 60,
        renderer: AgeCell,
      },
      {
        key: "memberNumber",
        label: "Member #",
        width: 110,
        type: "text",
        sortable: true,
        sortDirection: "asc",
        sortOrder: 15,
        sortFunction: (row: any) => get(row, ["restricted", "memberNumber"]),
        renderer: RestrictedCell,
      },
      {
        key: "gender",
        label: "Gender",
        width: 100,
        type: "select",
        options: getGenderOptions,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 8,
        renderer: DefaultInputCell,
      },
      {
        key: "addDivision",
        label: "",
        width: 45,
        renderer: AddDivisionCell,
      },
      {
        key: "rawOrEquipped",
        label: "R/E",
        width: 135,
        type: "select",
        sortable: true,
        sortDirection: "asc",
        sortOrder: 99,
        sortFunction: (row: any) => {
          const firstLifterDivision = get(row, ["divisions", 0]);
          if (!firstLifterDivision) {
            return "";
          }
          return meet.divisions[firstLifterDivision?.divisionId]?.rawOrEquipped;
        },
        options: getRawOrEquippedOptions,
        renderer: DivisionCell,
      },
      {
        key: "divisionId",
        label: "Awards Divisions",
        width: 300,
        type: "select",
        sortable: true,
        sortDirection: "asc",
        sortOrder: 100,
        sortFunction: (row: any) => {
          const firstLifterDivision = get(row, ["divisions", 0]);
          if (!firstLifterDivision) {
            return "";
          }
          return meet.divisions[firstLifterDivision?.divisionId]?.name;
        },
        options: getAwardDivisionOptions,
        renderer: DivisionCell,
      },
      {
        key: "declaredAwardsWeightClassId",
        label: "Declared Awards Weight Class",
        width: 230,
        type: "select",
        sortable: true,
        sortDirection: "asc",
        sortOrder: 101,
        sortFunction: (row: any) => {
          const firstLifterDivision = get(row, ["divisions", 0]);
          if (!firstLifterDivision) {
            return "";
          }
          const firstDivisionDoc =
            meet.divisions[firstLifterDivision.divisionId];
          if (!firstDivisionDoc) {
            return "";
          }
          const firstLifterWeightClass = getLifterDeclaredAwardsWeightClass(
            firstDivisionDoc,
            firstLifterDivision
          );
          if (!firstLifterWeightClass) {
            return "";
          }
          return (firstLifterWeightClass as WeightClass).maxWeight;
        },
        options: getWeightClassOptions,
        renderer: DivisionCell,
      },
      {
        key: "awardsWeightClass",
        label: "Awards Weight Class",
        width: 170,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 102,
        sortFunction: (row: any) => {
          const firstLifterDivision = get(row, ["divisions", 0]);
          if (!firstLifterDivision) {
            return "";
          }
          const firstLifterWeightClass = getLifterAwardsWeightClassDoc(
            meet,
            row,
            firstLifterDivision
          );
          if (!firstLifterWeightClass) {
            return "";
          }
          return (firstLifterWeightClass as WeightClass).maxWeight;
        },
        renderer: WeightClassCell,
      },
      {
        key: "bodyWeight",
        label: "Body Weight",
        width: 120,
        type: "number",
        sortable: true,
        sortFunction: (row: any) => toNumber(row.bodyWeight),
        sortDirection: "asc",
        sortOrder: 9,
        renderer: DefaultInputCell,
      },
      {
        key: "squatRackHeight",
        liftName: "squat",
        label: "Squat Rack Height",
        width: 160,
        type: "text",
        sortable: true,
        sortDirection: "asc",
        sortOrder: 16,
        renderer: RackHeightCell,
      },
      {
        key: "benchRackHeight",
        liftName: "bench",
        label: `${getBenchLabel(meet)} Rack Height`,
        width: 170,
        type: "text",
        sortable: true,
        sortDirection: "asc",
        sortOrder: 17,
        renderer: RackHeightCell,
      },
      {
        key: "lifts.squat.1",
        label: "Squat 1",
        width: 90,
        liftName: "squat",
        attemptNumber: "1",
        sortable: true,
        sortFunction: (row: any) =>
          toNumber(get(row, ["lifts", "squat", 1, "weight"])),
        sortDirection: "asc",
        sortOrder: 10,
        renderer: AttemptCell,
      },
      {
        key: "lifts.bench.1",
        label: `${getBenchLabel(meet)} 1`,
        width: 90,
        liftName: "bench",
        attemptNumber: "1",
        sortable: true,
        sortFunction: (row: any) =>
          toNumber(get(row, ["lifts", "bench", 1, "weight"])),
        sortDirection: "asc",
        sortOrder: 11,
        renderer: AttemptCell,
      },
      {
        key: "lifts.dead.1",
        label: "Dead 1",
        width: 90,
        liftName: "dead",
        attemptNumber: "1",
        sortable: true,
        sortFunction: (row: any) =>
          toNumber(get(row, ["lifts", "dead", 1, "weight"])),
        sortDirection: "asc",
        sortOrder: 12,
        renderer: AttemptCell,
      },
      {
        key: "wasDrugTested",
        label: "Drug Test?",
        width: 110,
        type: "select",
        options: getDrugTestOptions,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 13,
        renderer: DefaultInputCell,
      },
      {
        key: "phoneNumber",
        label: "Phone #",
        width: 160,
        type: "text",
        renderer: RestrictedCell,
      },
      {
        key: "country",
        label: "Country",
        width: 160,
        type: "select",
        options: getCountryOptions,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 7,
        renderer: DefaultInputCell,
      },
      {
        key: "streetAddress",
        label: "Street Address",
        width: 160,
        type: "text",
        renderer: RestrictedCell,
      },
      {
        key: "city",
        label: "City",
        width: 160,
        type: "text",
        renderer: RestrictedCell,
      },
      {
        key: "state",
        label: getStateLabel(meet),
        width: 160,
        type: "select",
        options: getStateOptions,
        sortable: true,
        sortDirection: "asc",
        sortOrder: 7,
        renderer: DefaultInputCell,
      },
      {
        key: "zipCode",
        label: "Zip Code",
        width: 120,
        type: "text",
        renderer: RestrictedCell,
      },
      {
        key: "email",
        label: "Email",
        width: 260,
        type: "text",
        renderer: RestrictedCell,
      },
      {
        key: "emergencyContactName",
        label: "Emergency Contact Name",
        width: 200,
        type: "text",
        renderer: RestrictedCell,
      },
      {
        key: "emergencyContactPhoneNumber",
        label: "Emergency Contact Phone #",
        width: 200,
        type: "text",
        renderer: RestrictedCell,
      },
      {
        key: "additionalItems",
        label: "Additional Items",
        width: 300,
        renderer: AdditionalItemsCell,
      },
    ];

    each(meet.entryConfig.customQuestions, (question) => {
      columns.push({
        key: question.id,
        label: question.text,
        width: 300,
        type: "text",
        renderer: RestrictedCell,
      });
    });

    return columns;
  };

  const getCellLines = (index: number, lifters: any) => {
    const rows = size(get(lifters, [index, "divisions"], 1));
    if (rows === 0) {
      return 1;
    }

    return rows * 1.085;
  };

  return (
    <SortableTable
      data={lifters}
      columns={getAllColumns()}
      getCellLines={getCellLines}
      meet={meet}
      numberOfFixedLeftColumns={2}
      media={media}
    />
  );
};

export default LiftersTable;
