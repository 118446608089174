import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Route,
  Switch,
  NavLink
} from 'react-router-dom';

import { getCurrentMeet } from 'selectors';

import CheckInSheet from './checkInSheet/CheckInSheet';
import EquipmentCheckSheet from './equipmentCheckSheet/EquipmentCheckSheet';
import WeighInSheet from './weighInSheet/WeighInSheet';
import RackHeightsSheet from './rackHeightsSheet/RackHeightsSheet';
import JurySheet from './jurySheet/JurySheet';
import ResultsSheet from './resultsSheet/ResultsSheet';
import CardsIndex from './cards/CardsIndex';

import './PaperworkIndex.scss';

class PaperworkIndex extends Component {


  render() {
    const { meet } = this.props;
    const baseUrl = `/meets/${meet._id}`;

    return (
      <div className="paperwork-index">
        <div className="tabs">
          <NavLink to={`${baseUrl}/paperwork/checkInSheet`} className="tab" activeClassName='active'>Check-In</NavLink>
          <NavLink to={`${baseUrl}/paperwork/equipmentCheckSheet`} className="tab" activeClassName='active'>Equipment Check</NavLink>
          <NavLink to={`${baseUrl}/paperwork/weighInSheet`} className="tab" activeClassName='active'>Weigh In</NavLink>
          <NavLink to={`${baseUrl}/paperwork/rackHeightsSheet`} className="tab" activeClassName='active'>Rack Heights</NavLink>
          <NavLink to={`${baseUrl}/paperwork/cards`} className="tab" activeClassName='active'>Cards</NavLink>
          <NavLink to={`${baseUrl}/paperwork/jury`} className="tab" activeClassName='active'>Jury</NavLink>
          <NavLink to={`${baseUrl}/paperwork/results`} className="tab" activeClassName='active'>Award Results</NavLink>
        </div>

        <Switch>
          <Route path='/meets/:meetId/paperwork/checkInSheet' component={CheckInSheet} />
          <Route path='/meets/:meetId/paperwork/equipmentCheckSheet' component={EquipmentCheckSheet} />
          <Route path='/meets/:meetId/paperwork/weighInSheet' component={WeighInSheet} />
          <Route path='/meets/:meetId/paperwork/rackHeightsSheet' component={RackHeightsSheet} />
          <Route path='/meets/:meetId/paperwork/cards' component={CardsIndex} />
          <Route path='/meets/:meetId/paperwork/jury' component={JurySheet} />
          <Route path='/meets/:meetId/paperwork/results' component={ResultsSheet} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    meet: getCurrentMeet(state, props)
  };
};

const actions = {};

export default connect(mapStateToProps, actions)(PaperworkIndex);
