import React, { Component } from "react";
import isObject from "lodash/isObject";
import map from "lodash/map";
import { resolveConflict } from "util/pouchActions";
import { Meet } from "types";

type ConflictProps = { meet: Meet; loser: any; winner: any };

class Conflict extends Component<ConflictProps, {}> {
  renderDocument(doc: any) {
    return (
      <div className="doc">
        {map(doc, (value, key) => {
          return (
            <div key={key}>
              <label>{key}: </label>
              {isObject(value) && this.renderDocument(value)}
              {!isObject(value) && <span>{value}</span>}
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { meet, loser, winner } = this.props;

    return (
      <div className="conflict">
        <div className="loser">
          <div className="title">Loser</div>
          {this.renderDocument(loser)}
          <div className="resolve">
            <button onClick={() => resolveConflict(meet._id, winner)}>
              Resolve As New Winner
            </button>
          </div>
        </div>
        <div className="winner">
          <div className="title">Winner</div>
          {this.renderDocument(winner)}
          <div className="resolve">
            <button onClick={() => resolveConflict(meet._id, loser)}>
              Resolve As Winner
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Conflict;
