import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink, Route, Switch, Redirect } from "react-router-dom";

import { getCurrentMeet, getLifter } from "selectors";
import FixedHeightWrapper from "app/meets/FixedHeightWrapper";
import LifterInfo from "./LifterInfo";
import AttemptTable from "./AttemptTable";
import LifterOrderTable from "./LifterOrderTable";
import LifterResults from "./LifterResults";
import EligibleRecords from "./EligibleRecords";

import "./LifterIndex.scss";

class LifterIndex extends Component {
  render() {
    const { match, lifter, meet } = this.props;

    if (!lifter || !meet) {
      return null;
    }

    return (
      <FixedHeightWrapper>
        <div className="lifter-index">
          <div className="tabs">
            <NavLink
              to={`${match.url}/info`}
              className="tab"
              activeClassName="active"
            >
              Info
            </NavLink>
            <NavLink
              to={`${match.url}/order`}
              className="tab"
              activeClassName="active"
            >
              Order
            </NavLink>
            <NavLink
              to={`${match.url}/attempts`}
              className="tab"
              activeClassName="active"
            >
              Attempts
            </NavLink>
            <NavLink
              to={`${match.url}/results`}
              className="tab"
              activeClassName="active"
            >
              Results
            </NavLink>
            {!!meet.records?.data && (
              <NavLink
                to={`${match.url}/records`}
                className="tab"
                activeClassName="active"
              >
                Records
              </NavLink>
            )}
          </div>

          <Switch>
            <Route
              path="/meets/:meetId/lifter/:lifterId/info"
              component={LifterInfo}
            />
            <Route
              path="/meets/:meetId/lifter/:lifterId/order"
              component={LifterOrderTable}
            />
            <Route
              path="/meets/:meetId/lifter/:lifterId/attempts"
              component={AttemptTable}
            />
            <Route
              path="/meets/:meetId/lifter/:lifterId/results"
              component={LifterResults}
            />
            <Route
              path="/meets/:meetId/lifter/:lifterId/records"
              component={EligibleRecords}
            />
            <Redirect
              from="/meets/:meetId/lifter/:lifterId"
              exact
              to={`/meets/${match.params.meetId}/lifter/${match.params.lifterId}/info`}
            />
            <Redirect
              from="/meets/:meetId/lifter/:lifterId/"
              exact
              to={`/meets/${match.params.meetId}/lifter/${match.params.lifterId}/info`}
            />
          </Switch>
        </div>
      </FixedHeightWrapper>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    meet: getCurrentMeet(state, props),
    lifter: getLifter(state, props),
  };
};

const actions = {};

export default withRouter(connect(mapStateToProps, actions)(LifterIndex));
