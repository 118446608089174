export let COUCH_DB_SERVER: string | undefined;
let RAILS_API: string | undefined;
let HOST: string | undefined;
if (process.env.NODE_ENV === "development") {
  // COUCH_DB_SERVER = "http://localhost:5984";
  //HOST = "http://localhost:3002";
  HOST = "http://localhost:3003";
  RAILS_API = HOST;
  COUCH_DB_SERVER = "https://couchdb.liftingcast.com";
  // COUCH_DB_SERVER = "https://couchdb.test.liftingcast.com";
  RAILS_API = "https://liftingcast.com";
} else {
  if (window.location.hostname.match(/[a-z]/i)) {
    HOST = `${window.location.protocol}//${window.location.hostname}`;
    COUCH_DB_SERVER = `${window.location.protocol}//couchdb.${window.location.hostname}`;
    RAILS_API = HOST;
  } else {
    // This is a locally hosted instance
    HOST = `${window.location.protocol}//${window.location.hostname}`;
    COUCH_DB_SERVER = `${window.location.protocol}//${window.location.hostname}:5984`;
    RAILS_API = HOST;
  }
}

export const getHost = () => {
  return HOST;
};

export const fetchWrapper = async (
  url: string,
  method: "POST" | "GET" | "HEAD",
  body?: any
) => {
  const config: { method: "POST" | "GET" | "HEAD"; headers?: any; body?: any } =
    {
      method: method,
    };

  if (method !== "HEAD") {
    config.headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
  }

  if (body) {
    config.body = JSON.stringify(body);
  }

  const response = await fetch(`${RAILS_API}${url}`, config);

  if (response.status === 204) {
    return;
  }

  const contentType = response.headers.get("content-type");

  if (contentType && contentType.includes("application/json")) {
    return response.json();
  }

  throw new TypeError("Non JSON response from meet creation api.");
};
