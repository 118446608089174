import React from "react";
import { connect } from "react-redux";
import findIndex from "lodash/findIndex";
import { withRouter } from "react-router-dom";

import { getFutureLiftingOrder } from "selectors";

const OutCell = ({ data, column, meet, style, futureLiftingOrder }) => {
  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    const out = findIndex(
      futureLiftingOrder,
      (row) => row.lifter._id === lifter._id
    );
    return (
      <div style={style} className="table-cell-inner">
        {out === -1 ? null : out ?? null}
      </div>
    );
  }
};

const mapStateToProps = (state, props) => {
  return {
    futureLiftingOrder: getFutureLiftingOrder(state, props),
  };
};

const actions = {};

export default withRouter(connect(mapStateToProps, actions)(OutCell));
