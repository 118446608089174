import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";

import { initMeet } from "util/pouchAdapter";

import { setCurrentMeetId, startLoadingMeet, doneLoadingMeet } from "actions";

import { getIsLoading, getCurrentMeet, getNotFound } from "selectors";

import Header from "app/meets/Header";
import SetupIndex from "./setup/SetupIndex";
import RegistrationIndex from "./registration/RegistrationIndex";
import LiveFeedIndex from "./liveFeed/LiveFeedIndex";
import LifterIndex from "./lifter/LifterIndex";
import DivisionIndex from "./division/DivisionIndex";
import MembershipCheckerIndex from "./membershipChecker/MembershipCheckerIndex";
import TeamIndex from "./team/TeamIndex";
import ImportIndex from "./import/ImportIndex";
import LiftersIndex from "./lifters/LiftersIndex";
import WeighInEntryIndex from "./weighInEntry/WeighInEntryIndex";
import PaperworkIndex from "./paperwork/PaperworkIndex";
import DivisionsIndex from "./divisions/DivisionsIndex";
import RecordsIndex from "./records/RecordsIndex";
import RunIndex from "./run/RunIndex";
import BoardIndex from "./board/BoardIndex";
import DisplayIndex from "./display/DisplayIndex";
import ResultsIndex from "./results/ResultsIndex";
import RefIndex from "./ref/RefIndex";
import ConflictsIndex from "./conflicts/ConflictsIndex";
import StatsIndex from "./stats/StatsIndex";
import RosterIndex from "./roster/RosterIndex";
import NotFound from "./NotFound";
import AuthRoute from "./AuthRoute";
import RouteWithProps from "./RouteWithProps";
import LogoIcon from "icons/LogoIcon";
import PasswordModal from "components/passwordModal/PasswordModal";
import DonateModal from "components/donateModal/DonateModal";
import RouteNotFound from "app/RouteNotFound";
import RackHeightsIndex from "./rackHeights/RackHeightsIndex";
import VirtualMeetSubmitIndex from "./virtualMeetSubmit/VirtualMeetSubmitIndex";
import ValidatorIndex from "./validator/ValidatorIndex";

class Meet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPasswordModalOpen: false,
      passwordResolve: null,
      passwordModalMode: null,
      isDonateModalOpen: false,
    };
  }

  componentDidMount() {
    initMeet(this.props.match.params.meetId);
    clearTimeout(this.timeout);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.meetId !== prevProps.match.params.meetId) {
      initMeet(this.props.match.params.meetId);
    }
  }

  onFinishBulkCreate = (count) => {
    // Hard to tell how long it takes to load bulk data. So wait an amount of time per item.
    // We want to not render child components so they don't have to respond to
    // store changes during a large data load.
    this.props.startLoadingMeet(this.props.meet._id);
    this.timeout = setTimeout(this.checkForSyncProgress, count * 100);
  };

  // Continue showing loading screen if sync is active after large data load.
  // Helps avoids bugs where user closes browser before sync finishes.
  checkForSyncProgress = () => {
    if (this.props.isSyncActive) {
      this.timeout = setTimeout(this.checkForSyncProgress, 200);
    } else {
      this.props.doneLoadingMeet(this.props.meet._id);
    }
  };

  closePasswordModal = () => {
    this.setState({ isPasswordModalOpen: false, passwordPromise: null });
  };

  openPasswordModal = (mode) => {
    return new Promise((resolve) => {
      this.setState({
        isPasswordModalOpen: true,
        passwordResolve: resolve,
        passwordModalMode: mode,
      });
    });
  };

  onCancelPasswordModal = () => {
    return this.state.passwordResolve({});
  };

  resolvePasswordPromise = (password, confirmationToken, emailAddress) => {
    return this.state.passwordResolve({
      password,
      confirmationToken,
      emailAddress,
    });
  };

  openDonateModal = () => {
    this.setState({ isDonateModalOpen: true });
  };

  closeDonateModal = () => {
    this.setState({ isDonateModalOpen: false });
  };

  render() {
    const { isPasswordModalOpen, passwordModalMode, isDonateModalOpen } =
      this.state;

    const { meet, isLoading, notFound } = this.props;

    if (notFound) {
      return <NotFound />;
    }

    return (
      <div className="meet">
        <PasswordModal
          isOpen={isPasswordModalOpen}
          onRequestClose={this.closePasswordModal}
          onOk={this.resolvePasswordPromise}
          onCancel={this.onCancelPasswordModal}
          mode={passwordModalMode}
          meetId={meet._id}
          meet={meet}
        />
        {isDonateModalOpen && (
          <DonateModal
            isOpen={isDonateModalOpen}
            onRequestClose={this.closeDonateModal}
            meet={meet}
          />
        )}
        <Header openPasswordModal={this.openPasswordModal} />
        <div className="content">
          {!isLoading && (
            <Switch>
              <Route path="/meets/:meetId/" exact component={ResultsIndex} />
              <AuthRoute
                path="/meets/:meetId/setup"
                component={SetupIndex}
                openPasswordModal={this.openPasswordModal}
                openDonateModal={this.openDonateModal}
              />
              <Route
                path="/meets/:meetId/registration"
                component={RegistrationIndex}
              />
              <Route path="/meets/:meetId/liveFeed" component={LiveFeedIndex} />
              <AuthRoute
                path="/meets/:meetId/import"
                component={ImportIndex}
                onFinishBulkCreate={this.onFinishBulkCreate}
                openPasswordModal={this.openPasswordModal}
              />
              <Route path="/meets/:meetId/records" component={RecordsIndex} />
              <AuthRoute
                path="/meets/:meetId/lifters"
                component={LiftersIndex}
                onFinishBulkCreate={this.onFinishBulkCreate}
                openPasswordModal={this.openPasswordModal}
              />
              <AuthRoute
                path="/meets/:meetId/weighInEntry"
                component={WeighInEntryIndex}
                openPasswordModal={this.openPasswordModal}
              />
              <AuthRoute
                path="/meets/:meetId/membershipChecker"
                component={MembershipCheckerIndex}
                openPasswordModal={this.openPasswordModal}
              />
              <Route
                path="/meets/:meetId/lifter/:lifterId"
                component={LifterIndex}
              />
              <Route
                path="/meets/:meetId/division/:divisionId/weightClass/:weightClassId"
                component={DivisionIndex}
              />
              <Route
                path="/meets/:meetId/team/:teamName"
                component={TeamIndex}
              />
              <AuthRoute
                path="/meets/:meetId/paperwork"
                component={PaperworkIndex}
                openPasswordModal={this.openPasswordModal}
              />
              <Route path="/meets/:meetId/roster" component={RosterIndex} />
              <AuthRoute
                path="/meets/:meetId/divisions"
                component={DivisionsIndex}
                onFinishBulkCreate={this.onFinishBulkCreate}
                openPasswordModal={this.openPasswordModal}
              />
              <RouteWithProps
                path="/meets/:meetId/results"
                component={ResultsIndex}
                openDonateModal={this.openDonateModal}
              />
              <AuthRoute
                path="/meets/:meetId/platforms/:platformId/run"
                component={RunIndex}
                openPasswordModal={this.openPasswordModal}
              />
              <Route
                path="/meets/:meetId/platforms/:platformId/board"
                component={BoardIndex}
              />
              <Route
                path="/meets/:meetId/platforms/:platformId/display"
                component={DisplayIndex}
              />
              <AuthRoute
                path="/meets/:meetId/platforms/:platformId/refs/:position"
                component={RefIndex}
                openPasswordModal={this.openPasswordModal}
                blockLocalAccess={false}
              />
              <AuthRoute
                path="/meets/:meetId/conflicts"
                component={ConflictsIndex}
                openPasswordModal={this.openPasswordModal}
              />
              <Route path="/meets/:meetId/stats" component={StatsIndex} />
              <Route
                path="/meets/:meetId/rackHeights"
                component={RackHeightsIndex}
              />
              <Route
                path="/meets/:meetId/virtualMeetSubmit"
                component={VirtualMeetSubmitIndex}
              />
              <Route
                path="/meets/:meetId/validator"
                component={ValidatorIndex}
              />
              <Route component={RouteNotFound} />
            </Switch>
          )}
          {isLoading && (
            <div className="loading-wrapper">
              <LogoIcon className="logo loop" loading />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    isLoading: getIsLoading(state, props),
    notFound: getNotFound(state, props),
    meet: getCurrentMeet(state, props),
  };
};

const actions = {
  setCurrentMeetId,
  startLoadingMeet,
  doneLoadingMeet,
};

export default connect(mapStateToProps, actions)(Meet);
